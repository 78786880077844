import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = styled.button`
  border: none;
  background-color: transparent;
  padding: 12px;
  outline: 0;
  position: absolute;
  top: 10px;
  right: 10px;
`;

function CloseButton(props) {
  return (
    <Button aria-label="close navigation" {...props}>
      <svg
        width="24"
        height="24"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
      >
        <path
          fill="#fff"
          d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z"
        />
      </svg>
    </Button>
  );
}

CloseButton.propTypes = {};

CloseButton.defaultProps = {};

export default CloseButton;
