const sizes = {
  md: '40em',
  lg: '64em',
};

export const media = {
  // not small
  ns: `@media screen and (min-width: ${sizes.md})`,
  // small
  sm: `@media screen and (max-width: ${sizes.md})`,
  // mediam
  md: `@media screen and (min-width: ${sizes.md}) and (max-width: ${sizes.lg})`,
  // large
  lg: `@media screen and (min-width: ${sizes.lg})`,
};

const theme = {
  colors: {
    gray: 'rgba(0,0,0,0.7)',
    black: '#000',
    white: '#fff',
  },

  fonts: {
    playfair: 'Playfair', //
    serif: 'Playfair',
    sansSerif: 'Muli',
    muli: 'Muli',
  },

  sizes: {
    f1: `
      font-size: 32px;
      line-height: 36px;
      ${media.md} {
        font-size: 36px;
        line-height: 41px;
      }
      ${media.lg} {
        font-size: 48px;
        line-height: 55px;
      }
    `,
    f2: `
    font-size: 24px;
    line-height: 28px;
    ${media.md} {
      font-size: 30px;
      line-height: 36px;
    }
    ${media.lg} {
      font-size: 36px;
      line-height: 41px;
    }
    `,
    f3: `
    font-size: 21px;
    line-height: 24px;
    ${media.ns} {
      font-size: 24px;
      line-height: 28px;
    }
  `,
    f4: `
    font-size: 18px;
    line-height: 24px;
    ${media.ns} {
      font-size: 21px;
      line-height: 24px;
    }
  `,
    f5: `
    font-size: 13px;
    line-height: 16px;
  `,
    p: `
    font-size: 17px;
    line-height: 25px;
    ${media.md} {
      font-size: 20px;
      line-height: 30px;
    }
    ${media.lg} {
      font-size: 20px;
      line-height: 30px;
    }
    `,
    smallP: `
    font-size: 15px;
    line-height: 23px;
    ${media.md} {
      font-size: 16px;
      line-height: 24px;
    }
    ${media.lg} {
      font-size: 16px;
      line-height: 24px;
    }
    `,
    smallestP: `
      font-size: 14px;
      line-height: 21px;
    `,
  },
};

export default theme;
