import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Link as GatsbyLink } from 'gatsby';
import CloseButton from './CloseButton';

// const underlineStyle = css`
//   background-repeat: repeat-x;
//   background-image: linear-gradient(
//     to right,
//     currentColor 100%,
//     currentColor 0
//   );
//   background-size: 15px 1px;
//   background-position: 0 100%;
//   white-space: initial;
// `;

const StyledMobileNav = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: opacity 200ms ease-out, transform 200ms ease-out;
  transform: translateY(${({ open }) => (open ? 0 : 20)}px);
  z-index: 3;
`;

const List = styled.ul`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  list-style: none;
`;

const ListItem = styled.li`
  ${({ theme }) => theme.sizes.f2};
  margin: 15px 0;
`;

const Link = styled(GatsbyLink)`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.serif};
  text-decoration: none;
  padding-bottom: 2px;
`;

function MobileNav({ open, onClose }) {
  return (
    <StyledMobileNav open={open}>
      <CloseButton onClick={onClose} />
      <List>
        <ListItem>
          <Link to="/">Home</Link>
        </ListItem>
        <ListItem>
          <Link to="/about/">About</Link>
        </ListItem>
        <ListItem>
          <Link to="/furniture/">Furniture</Link>
        </ListItem>
        <ListItem>
          <Link to="/contact/">Contact</Link>
        </ListItem>
      </List>
    </StyledMobileNav>
  );
}

MobileNav.propTypes = {
  open: PropTypes.bool.isRequired,
};

MobileNav.defaultProps = {};

export default MobileNav;
