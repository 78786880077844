import styled from 'styled-components';

const Container = styled.div`
  box-sizing: border-box;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3vw;
  padding-bottom: 3vw;
  padding-left: 4vw;
  padding-right: 4vw;
`;

export default Container;
