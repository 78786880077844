import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

function SEO({ description, imageUrl, title }) {
  // {pathname && <meta property="og:url" content={`https://burntcreekcustom.com${pathname}`} />}
  return (
    <Helmet>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      {/* <meta name="twitter:creator" content="@burntcreekcustom" /> */}
      <meta name="twitter:domain" content="burntcreekcustom.com" />
      {/* <meta name="twitter:site" content="burntcreek" /> */}
      {description && <meta name="twitter:description" content={description} />}
      {title && <meta name="twitter:title" content={title} />}
      {imageUrl && <meta name="twitter:image" content={`https:${imageUrl}`} />}

      {/* Open graph tags */}
      <meta property="og:type" content="website" />
      {description && (
        <meta property="og:description" content={description.description} />
      )}
      {imageUrl && <meta property="og:image" content={`https:${imageUrl}`} />}
      {title && <meta property="og:title" content={title} />}
    </Helmet>
  );
}

SEO.propTypes = {
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
};

SEO.defaultProps = {
  imageUrl: null,
};

export default SEO;
