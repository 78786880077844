import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { media } from 'styles/theme';

import { Link as GatsbyLink } from 'gatsby';
import Container from '../Container';
import Logo from '../Logo';
import MobileMenuIcon from './MobileMenuIcon';
import MobileNav from './MobileNav';

const StyledNav = styled.nav`
  display: flex;
  position: relative;
  width: 100%;
  z-index: 2;
`;

const List = styled.ul`
  align-items: center;
  display: flex;
  list-style: none;
  padding-left: 0;
  width: 100%;
`;

const ListItem = styled.li`
  display: ${({ alwaysShow }) => (alwaysShow ? 'block' : 'none')};
  ${media.ns} {
    display: block;
  }
  a {
    color: ${({ color }) => color};
    font-family: ${({ theme }) => theme.fonts.playfair};
    font-size: calc(0.24vw + 1rem);
    text-decoration: none;
  }
  cursor: pointer;
  margin-right: 26px;
  :last-of-type {
    margin-right: 0;
  }
`;

const underlineStyle = css`
  background-repeat: repeat-x;
  background-image: linear-gradient(
    to right,
    currentColor 100%,
    currentColor 0
  );
  background-size: 15px 1px;
  background-position: 0 100%;
  white-space: initial;
`;

const Link = styled(({ active, ...rest }) => <GatsbyLink {...rest} />)`
  padding-bottom: 2px;
  text-decoration: none;
  transition: opacity 200ms ease-out;
  ${({ active }) =>
    active &&
    `
    ${underlineStyle}
  `};
  :hover {
    ${underlineStyle}
    opacity: .5;
  }
`;

const TitleLink = styled(GatsbyLink)`
  align-items: center;
  display: flex;
`;

const Spacer = styled.li`
  flex: 1;
`;

const Title = styled.span`
  font-weight: 400;
  ${({ theme }) => theme.sizes.f3};
  margin-left: 10px;
`;

function Nav({ location, navTheme, ...rest }) {
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);
  const color = navTheme === 'light' ? '#fff' : '#000';

  React.useEffect(() => {
    document.body.style.overflow = mobileNavOpen ? 'hidden' : 'initial';
  }, [mobileNavOpen]);

  React.useEffect(() => {
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  function checkActive(path) {
    return path === location.pathname;
  }

  return (
    <>
      <Container style={{ width: '100%' }}>
        <StyledNav {...rest}>
          <List>
            <ListItem alwaysShow color={color}>
              <TitleLink to="/">
                <Logo height={45} fill={color} />{' '}
                <Title>Burnt Creek Custom</Title>
              </TitleLink>
            </ListItem>
            <Spacer />
            <ListItem color={color}>
              <Link active={checkActive('/about/')} to="/about/">
                About
              </Link>
            </ListItem>
            <ListItem color={color}>
              <Link active={checkActive('/furniture/')} to="/furniture/">
                Furniture
              </Link>
            </ListItem>
            <ListItem color={color}>
              <Link active={checkActive('/contact/')} to="/contact/">
                Contact
              </Link>
            </ListItem>
            <MobileMenuIcon
              fill={color}
              openNav={() => setMobileNavOpen(true)}
            />
          </List>
        </StyledNav>
      </Container>
      <MobileNav open={mobileNavOpen} onClose={() => setMobileNavOpen(false)} />
    </>
  );
}

Nav.propTypes = {
  location: PropTypes.any.isRequired,
  navTheme: PropTypes.oneOf(['light', 'dark']),
};

Nav.defaultProps = {
  navTheme: 'light',
};

export default Nav;
