import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import Container from 'components/Container';
import GatsbyLink from 'gatsby-link';
import InstagramLogo from './InstagramLogo';

const FooterLink = styled(GatsbyLink)`
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.serif};
  padding-bottom: 2px;
  text-decoration: none;
  transition: opacity 200ms ease-out;
  ${({ theme }) => theme.sizes.f3};
  margin: 0 10px;
  background-repeat: repeat-x;
  background-image: linear-gradient(
    to right,
    currentColor 100%,
    currentColor 0
  );
  background-size: 15px 1px;
  background-position: 0 100%;
  white-space: initial;
  :hover {
    opacity: 0.5;
  }
`;

const Ul = styled.ul`
  display: flex;
  justify-content: center;
`;

const Li = styled.li`
  // list-style: none;
`;

const SocialSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 0 80px;
`;

function Footer() {
  return (
    <Container as="footer" style={{ marginTop: 60 }}>
      <Ul>
        <Li>
          <FooterLink to="/about/">About</FooterLink>
        </Li>
        <Li>
          <FooterLink to="/furniture/">Furniture</FooterLink>
        </Li>
        <Li>
          <FooterLink to="/contact/">Contact</FooterLink>
        </Li>
      </Ul>
      <SocialSection>
        <FooterLink
          as="a"
          href="https://instagram.com/burntcreek"
          target="_blank"
          rel="noopener noreferrer"
          style={{ backgroundImage: 'none' }}
        >
          <InstagramLogo height="30" />
        </FooterLink>
      </SocialSection>
    </Container>
  );
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
