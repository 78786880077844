import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import theme from 'styles/theme';

import Footer from 'components/Footer';

import 'styles/reset.css';

// fonts
import 'styles/fonts/playfair/stylesheet.css';
import 'styles/fonts/muli/stylesheet.css';

const StyledLayout = styled.div`
  font-family: 'Muli', -apple-system, BlinkMacSystemFont, 'avenir next', avenir,
    helvetica, 'helvetica neue', ubuntu, roboto, noto, 'segoe ui', arial,
    sans-serif;
`;

function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <StyledLayout className="Layout">
        {children}
        <Footer />
      </StyledLayout>
    </ThemeProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.any,
};

Layout.defaultProps = {};

export default Layout;
