import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/theme';

const Button = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: 0;
  padding: 12px 0 12px 12px;
  ${media.ns} {
    display: none;
  }
`;

function MobileMenuIcon({ fill, openNav }) {
  return (
    <Button aria-label="open navigation" onClick={openNav}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
      >
        <path
          d="M24 18v1h-24v-1h24zm0-6v1h-24v-1h24zm0-6v1h-24v-1h24z"
          fill={fill}
        />
        <path
          d="M24 19h-24v-1h24v1zm0-6h-24v-1h24v1zm0-6h-24v-1h24v1z"
          fill={fill}
        />
      </svg>
    </Button>
  );
}

MobileMenuIcon.propTypes = {
  fill: PropTypes.string,
  openNav: PropTypes.func.isRequired,
};

MobileMenuIcon.defaultProps = {
  fill: '#000',
};

export default MobileMenuIcon;
